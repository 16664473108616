import React from 'react'
import Card from 'react-bootstrap/Card'

function Footer() {
  return (
    <footer className='footer'>
      <h5>Copyright 2022</h5>
    </footer>
  )
}

export default Footer